import React, { useState, useEffect } from 'react';
import { SupportForm, Loader } from '@bcmi-labs/react-components';

import Layout from '../../../components/layout';
import SEO from '../../../components/seo';

import '../../support-form.scss';

const BecomeReseller = () => {
  const API_URL = 'https://api2.arduino.cc';

  const [countries, setCountries] = useState(null);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    fetch(`${API_URL}/countries/v1`)
      .then(response => response.json())
      .then(data => setCountries(data));
  }, []);

  useEffect(() => {
    if (!countries) {
      return;
    }
    setFormConfig({
      endpoint: 'https://support-api.arduino.cc/v2/tickets',
      title: (
        <div className="distributor-header">
          <div>Become a Distributor</div>
          <span className="distributor-header--description">
            Fill the form below to join our distributor selection process.
          </span>
        </div>
      ),
      mustBeConfirmed: true,
      onSubmissionMessage: 'Thanks for your feedback\n\nYou can enter another request',
      tags: ['crm-form'],
      reCaptchaKey: '6LdQJrIZAAAAAHDMUTznb7FvAc_Kf-M3lcFsG5JI',
      fields: [
        [
          {
            id: 'step1',
            type: 'empty',
            className: 'distributor-part',
            children: (
              <span>
                Part 1 of 4: <b>Tell us more about yourself</b>
              </span>
            ),
          },
        ],
        [{ id: 'name', type: 'input', label: 'Name', placeholder: 'Enter your Name', required: true }],
        [
          { id: 'email', type: 'email', label: 'Email', placeholder: 'Enter e-mail address', required: true },
          { id: 'phone-number', attachToBody: true, label: 'Phone Number', type: 'phone', required: true },
        ],
        [{ id: 'div2', type: 'hr-line' }],
        [
          {
            id: 'step2',
            type: 'empty',
            className: 'distributor-part',
            children: (
              <span>
                Part 2 of 4: <b>Tell us more about your company</b>
              </span>
            ),
          },
        ],
        [
          {
            id: 'subject',
            type: 'input',
            generateFn: form => {
              return `Become an Arduino distributor - ${form['company-name']}`;
            },
          },
        ],
        [
          {
            id: 'company-name',
            attachToBody: true,
            label: 'Company Name',
            type: 'input',
            required: true,
          },
          {
            id: 'customer-type',
            attachToBody: true,
            label: 'Company Type:',
            type: 'select',
            isMulti: true,
            values: [
              { value: 'You are mainly a Retailer (you sell directly to consumers)', tags: [''] },
              { value: 'You are mainly a Distributor (you sell to resellers and retailers).', tags: [''] },
              { value: 'You are a balanced B2C and B2B company.', tags: [''] },
              { value: 'Other.', tags: [''] },
            ],
            required: true,
          },
        ],
        [
          {
            id: 'company-web',
            customId: 360006722999,
            label: 'Website',
            type: 'input',
            required: true,
          },
        ],
        [
          {
            id: 'country',
            customId: 360000157880,
            label: 'Country',
            type: 'select',
            placeholder: 'Select country',
            values: countries.map(country => {
              return {
                value: country.code,
                label: country.name,
                tags: [country.code],
              };
            }),
            required: true,
          },
          {
            id: 'year-est',
            attachToBody: true,
            label: 'Foundation Year',
            type: 'input',
            required: true,
          },
        ],
        [
          {
            id: 'role',
            customId: 360000157960,
            label: 'Your Role',
            type: 'select',
            values: [
              {
                value: 'Owner',
                tags: [''],
              },
              {
                value: 'Sales manager',
                tags: [''],
              },
              {
                value: 'Buyer',
                tags: [''],
              },
              {
                value: 'Other',
                tags: [''],
              },
            ],
            required: true,
          },
          {
            id: 'revenue',
            attachToBody: true,
            label: 'Annual Revenue (US$)',
            type: 'select',
            values: [
              {
                value: '0-$50k',
                tags: [''],
              },
              {
                value: '$50k-$100k',
                tags: [''],
              },
              {
                value: '$100k-$250k',
                tags: [''],
              },
              {
                value: '$250k +',
                tags: [''],
              },
            ],
            required: true,
          },
        ],
        [{ id: 'div3', type: 'hr-line' }],
        [
          {
            id: 'step3',
            type: 'empty',
            className: 'distributor-part',
            children: (
              <span>
                Part 3 of 4: <b>Your company sectors and customers</b>
              </span>
            ),
          },
        ],
        // company sectors
        [
          {
            id: 'country',
            attachToBody: true,
            label: 'Selling Markets',
            type: 'select',
            isMulti: true,
            placeholder: 'Type to search',
            helperText: 'Select the countries that belong to your main selling markets.',
            values: countries.map(country => {
              return {
                value: country.code,
                label: country.name,
                tags: [country.code],
              };
            }),
            required: true,
          },
        ],
        [
          {
            id: 'products',
            attachToBody: true,
            label: 'Main Brands and Products:',
            placeholder: 'Enter Request Message',
            type: 'textarea',
            required: true,
          },
        ],
        [
          {
            id: 'customers',
            label: 'Main Customers',
            attachToBody: true,
            helperText: 'Tell us who your main customers are. You can select more than one type.',
            type: 'checkboxgroup',
            values: [
              { value: 'Makers and DIY enthusiast', label: 'Makers and DIY enthusiast', tags: [''] },
              {
                value: 'Companies that create projects with Arduino',
                label: 'Companies that create projects with Arduino',
                tags: [''],
              },
              { value: 'Schools and students', label: 'Schools and students', tags: [''] },
              { value: 'Other.', label: 'Other.', tags: [''] },
            ],
            required: true,
          },

          {
            id: 'edu-offer',
            attachToBody: true,
            label: 'Do you know the Arduino Education offering?',
            type: 'radiogroup',
            values: [
              { value: 'Yes', label: 'Yes', tags: [''] },
              { value: 'No', label: 'No', tags: [''] },
            ],
            required: true,
          },
        ],
        [{ id: 'div4', type: 'hr-line' }],
        [
          {
            id: 'step4',
            type: 'empty',
            className: 'distributor-part',
            children: (
              <span>
                Part 4 of 4: <b>The way you work</b>
              </span>
            ),
          },
        ],
        // The way you work
        [
          {
            id: 'strategy',
            attachToBody: true,
            label: 'What is your go-to-market strategy?',
            placeholder: 'Enter Request Message',
            type: 'textarea',
            required: true,
          },
        ],
        [
          {
            id: 'sales-team',
            attachToBody: true,
            label: 'How is your sales team structured?',
            placeholder: 'Enter Request Message',
            type: 'textarea',
            required: true,
          },
        ],
        [
          {
            id: 'why-arduino-reseller',
            attachToBody: true,
            label: 'Why do you want to become an Arduino distributor?',
            placeholder: 'Enter Request Message',
            type: 'textarea',
            required: true,
          },
        ],
        [
          {
            id: 'mkr1010-nano33ble',
            attachToBody: true,
            label: 'What do you think of the Arduino MKR 1010 and the Nano BLE Sense?',
            placeholder: 'Enter Request Message',
            type: 'textarea',
            required: true,
          },
        ],
        [
          {
            id: 'tos',
            label:
              'I confirm to have read the [privacy policy](https://www.arduino.cc/PrivacyPolicy/en/) and to accept the [Terms of service](https://www.arduino.cc/en/Main/TermsOfService)',
            type: 'checkbox',
            required: true,
          },
        ],
      ],
    });
  }, [countries]);

  if (!formConfig) {
    return <Loader />;
  }

  return (
    <Layout currentLocale="en">
      <SEO title="Apply as a Distributor" />
      <main className="distributor-form page">
        <section>
          <div className="page-content">
            <div id="become-reseller">
              <SupportForm config={formConfig} />
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default BecomeReseller;
